<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div
          class="d-flex justify-content-between align-items-center pt-50 mb-2"
        >
          <div>
            <h2 class="font-weight-bolder m-0">Departamentos</h2>
          </div>
          <div class="d-flex align-items-center">
            <div>
              <b-link :to="{ name: 'createDepartment' }">
                <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                  {{ "Añadir nuevo departamento" }}
                </b-button>
              </b-link>
            </div>
            <b-input-group class="input-group">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchTerm"
                type="text"
                class="d-inline-block"
                :placeholder="'Búsqueda'"
                @keydown.enter.native="handleSearch"
              />
              <b-button
                @click="handleSearch"
                variant="primary"
                size="sm"
                class="mr-1 ml-1"
              >
                {{ "Buscar" }}
              </b-button>
            </b-input-group>
          </div>
        </div>
        <b-card no-body>
          <b-table hover responsive :items="items" :fields="columns">
            <template #cell(active)="data">
              <b-badge variant="light-success" v-if="data.item.active">
                ACTIVO
              </b-badge>
              <b-badge variant="light-danger" v-else> DESACTIVO </b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <a
                    :href="getImgUrl(data.item.SKU)"
                    target="_blank"
                    download
                    v-if="getImgUrl(data.item.SKU)"
                    class="d-inline-block px-50 text-dark"
                >
                <feather-icon icon="DownloadIcon" />
                </a>
                <b-link
                  :to="{ name: 'editDepartment', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  class="d-inline-block px-50 text-danger"
                  @click="deleteDepartment(data.item.id, data.item.name)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BFormInput,
  BBadge,
  BLink,
  BButton,
  BCard,
  BTable,
  BCol,
  BRow,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";

export default {
  components: {
    BCard,
    BFormInput,
    BBadge,
    BTable,
    BButton,
    BCol,
    BRow,
    BLink,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      log: [],
      dir: false,
      columns: [
        {
          label: "Nombre",
          key: "name",
        },
        {
          label: "Estado",
          key: "active",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      items: "departments/getItems",
      totalItems: "departments/getTotalItems",
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.chargeData();
  },
  methods: {
    ...mapActions({
      list: "departments/getListDepartments",
      delete: "departments/delete",
    }),
    getImgUrl(pet) {
      try {
        var image = require('@/assets/images/qrs/' + pet + ".png");
        return image
      } catch (ex) {
        return ''
      }
    },
    chargeData() {
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    deleteDepartment(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
